import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import Row from '@amzn/meridian/row';
import '../styles.scss';

export default function componentDidMount() {
  // This is the "qsbosco" API link. You may want to put your own qsbosco link here.
  //const api = 'https://ky808eo7p8.execute-api.us-east-1.amazonaws.com/test';
  const api = 'https://ysumgjkjg0.execute-api.us-east-1.amazonaws.com/test/QSBosco';
  const [responseData, setData] = useState("");
 
  useEffect(() => {
    fetchQuickSight();
  }, []);

  async function fetchQuickSight() {
    const result = await axios
      
      .get(api)
      .then((response) => {
        console.log(response);
        const startStr = ((response.data.body).indexOf("EmbedUrl"));
        const endStr = ((response.data.body).indexOf("isauthcode=true"));
        setData((response.data.body).substring(startStr + 12, endStr + 15));
      }).catch((error) => {
        console.log("error!");
        console.log(error);
      })
      

  }

  function ForecastQuicksightDashboard() {
    
    const ref = useRef<HTMLDivElement | null>(null);
    if (responseData.length < 2)
      return (<></>);
    useEffect(() => {
      var { innerWidth: width, innerHeight: height } = window;
      const dashboard = QuickSightEmbedding.embedDashboard({
        url: responseData,
        // ref.current is definitely not null in useEffect
        container: ref.current || '',
        locale: 'en-US',
        footerPaddingEnabled: false,
        height: innerHeight,
        iframeResizeOnSheetChange: true, // use this option in combination with height: AutoFit,
        // to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
        width: innerWidth,
      });
    });

    return <div id={'embeddingContainer'} ref={ref} />;
  }
  return (<>
    <React.Fragment>
      <Row alignmentHorizontal='center'>
        <ForecastQuicksightDashboard />
      </Row>
    </React.Fragment>

  </>);
}

